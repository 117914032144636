import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BrandMarkWithWordMarkLeft } from '../../design/icons/brandMark/BrandMark';
import Spacing from '../../design/spacing/Spacing';
import Link from '../link/Link';
import { LinkType } from '../link/types';
import { BurgerButton } from './components/buttons/BurgerButton';
import { ContentContainer } from './components/containers/ContentContainer';
import { HeaderContainer } from './components/containers/HeaderContainer';
import { Ctas, CtasProps } from './components/ctaSection/Ctas';
import { Menu, MenuProps } from './components/menu/Menu';
import { ChangeDropdownStatus, NavItemsContext, OpenDropdowns } from './components/menu/NavItemsContext';

export type OnMount = ({ closeAll }: { closeAll: () => void }) => void;

type Props = { brandLogoLink: LinkType['link']; onMount?: OnMount } & CtasProps & MenuProps;
const CenteredWrapper = styled.div`
  max-width: 2000px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * Renders the header.
 */
const Header: FC<PropsWithChildren<Props>> = ({
  brandLogoLink,
  megaDropdownCta,
  primaryCta,
  secondaryCta1,
  secondaryCta2,
  tourCtaMobile,
  items,
  onMount,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState<OpenDropdowns>({});

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const changeDropdownStatus: ChangeDropdownStatus = useCallback(
    (headingName, open) => setOpenDropdowns({ [headingName]: !!open }),
    []
  );

  useEffect(() => {
    onMount && onMount({ closeAll: () => setOpenDropdowns({}) });
  }, [onMount]);

  return (
    <>
      <NavItemsContext.Provider value={{ dropdownsStatus: openDropdowns, changeDropdownStatus }}>
        <HeaderContainer data-testid="header" offset={0}>
          <CenteredWrapper>
            <ContentContainer>
              <Spacing left={{ base: '12px' }}>
                <Link link={brandLogoLink} data-testid="logo-testid">
                  <BrandMarkWithWordMarkLeft />
                </Link>
              </Spacing>
              <Spacing left={{ base: 'none', breakpointMedium: '12px' }} right={{ breakpointMedium: '12px' }}>
                <Menu items={items} open={menuOpen} megaDropdownCta={megaDropdownCta} />
              </Spacing>
            </ContentContainer>
            <ContentContainer>
              <Ctas
                tourCtaMobile={tourCtaMobile}
                primaryCta={primaryCta}
                secondaryCta1={secondaryCta1}
                secondaryCta2={secondaryCta2}
                open={menuOpen}
              />
              <BurgerButton data-testid="mobile-burger-button" onClick={toggleMenu} toggled={menuOpen} />
            </ContentContainer>
          </CenteredWrapper>
        </HeaderContainer>
      </NavItemsContext.Provider>
    </>
  );
};

export default Header;
