import { SliceZone } from '@prismicio/react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { faqData } from '../../components/SEO/structuredData/FAQ.queryData';
import {
  websiteSchema,
  expenseManagementSchema,
  businessCreditCardSchema,
} from '../../components/SEO/structuredData/schemas';
import { generateFaqSchema } from '../../components/SEO/structuredData/utils';
import PageContainer from '../../pageContainers/PageContainer';
import ApplePayData from '../../slices/applePay/data/ApplePay.queryData';
import BottomCtaData from '../../slices/bottomCta/data/BottomCta.queryData';
import CardsData from '../../slices/cards/data/Cards.queryData';
import CompanyQuoteData from '../../slices/companyQuote/data/CompanyQuote.queryData';
import ContentHubCardsData from '../../slices/contentHubCards/data/ContentHubCards.queryData';
import ContentInfoBlockData from '../../slices/contentInfoBlock/data/ContentInfoBlock.queryData';
import DemoData from '../../slices/demoForm/data/Demo.queryData';
import EmailCaptureData from '../../slices/emailCapture/data/EmailCapture.queryData';
import FaqsData from '../../slices/faqs/data/Faqs.queryData';
import FeaturePricingComparisonData from '../../slices/featurePricingComparison/data/FeaturePricingComparison.queryData';
import FiveColumnsPanelData from '../../slices/fiveColumnsPanel/data/FiveColumnsPanel.queryData';
import FxCalculatorData from '../../slices/fxCalculator/data/fxCalculator.queryData';
import GridAreaCardsData from '../../slices/gridAreaCards/data/GridAreaCards.queryData';
import HeroData from '../../slices/hero/data/Hero.queryData';
import LogosData from '../../slices/logos/data/Logo.queryData';
import PricingData from '../../slices/pricing/data/Pricing.queryData';
import QuoteData from '../../slices/quote/data/Quote.queryData';
import RelatedResourcesData from '../../slices/relatedResources/RelatedResources.queryData';
import SocialProofData from '../../slices/socialProof/data/SocialProof.queryData';
import { SponsorsData } from '../../slices/sponsors/data/Sponsors.queryData';
import StatsData from '../../slices/stats/data/Stats.queryData';
import Steps from '../../slices/steps/data/Steps.queryData';
import ThreeColumnCardsData from '../../slices/threeColumnCards/data/ThreeColumnCards.queryData';
import ThreeColumnFiveCardsData from '../../slices/threeColumnFiveCards/data/ThreeColumnFiveCards.queryData';
import TitleData from '../../slices/title/data/Title.queryData';
import TwoColFloatingContentData from '../../slices/twoColFloatingContent/data/TwoColFloatingContent.queryData';
import TwoColumnContentImageData from '../../slices/twoColumnContentImage/data/TwoColumnContentImage.queryData';
import TwoColumnFourCardsData from '../../slices/twoColumnFourCards/data/TwoColumnFourCards.queryData';
import TwoColumnProcessData from '../../slices/twoColumnProcess/data/TwoColumnProcess.queryData';
import TwoColumnThreeCardsData from '../../slices/twoColumnThreeCards/data/TwoColumnThreeCards.queryData';
import withBottomSpacing from '../../slices/utils/withBottomSpacing';
import VideoEmbedData from '../../slices/videoEmbed/data/VideoEmbed.queryData';
import WysiwygData from '../../slices/wysiwyg/data/Wysiwyg.queryData';
import { prismicData } from '../../utils/lodashHelpers';

const components = {
  hero: withBottomSpacing(HeroData),
  quote: withBottomSpacing(QuoteData),
  faqs: withBottomSpacing(FaqsData),
  three_column_cards: withBottomSpacing(ThreeColumnCardsData),
  two_column_three_cards: withBottomSpacing(TwoColumnThreeCardsData),
  two_column_four_cards: withBottomSpacing(TwoColumnFourCardsData),
  three_column_five_cards: withBottomSpacing(ThreeColumnFiveCardsData),
  bottom_cta: withBottomSpacing(BottomCtaData),
  social_proof: withBottomSpacing(SocialProofData),
  two_column_content_image: withBottomSpacing(TwoColumnContentImageData),
  two_col_floating_content: withBottomSpacing(TwoColFloatingContentData),
  five_columns_panel: withBottomSpacing(FiveColumnsPanelData),
  steps: withBottomSpacing(Steps),
  logos: withBottomSpacing(LogosData),
  content_info_block: withBottomSpacing(ContentInfoBlockData),
  title: withBottomSpacing(TitleData),
  apple_pay: withBottomSpacing(ApplePayData),
  pricing: withBottomSpacing(PricingData),
  company_quote: withBottomSpacing(CompanyQuoteData),
  demo: withBottomSpacing(DemoData),
  wysiwyg: withBottomSpacing(WysiwygData),
  content_hub_cards: withBottomSpacing(ContentHubCardsData),
  grid_area_cards: withBottomSpacing(GridAreaCardsData),
  cards: withBottomSpacing(CardsData),
  stats: withBottomSpacing(StatsData),
  email_capture: withBottomSpacing(EmailCaptureData),
  sponsors: withBottomSpacing(SponsorsData),
  related_resources: withBottomSpacing(RelatedResourcesData),
  video_embed: withBottomSpacing(VideoEmbedData),
  feature_pricing_comparison: withBottomSpacing(FeaturePricingComparisonData),
  fx_calculator: withBottomSpacing(FxCalculatorData),
  two_column_process: withBottomSpacing(TwoColumnProcessData),
};

/**
 * Renders Prismic Page document and maps slice data to be rendered by their components in order.
 */
const Page = ({ data }: PageProps<Queries.PageQuery>) => {
  const pageData = data?.prismicPage;
  const headerId = prismicData(pageData, 'header.id');
  const footerId = prismicData(pageData, 'footer.id');
  const slices = prismicData(pageData, 'slices');
  const uid = pageData?.url ?? '';
  const isCurrentPath = (path: string) => uid === path;

  const faqSchema = generateFaqSchema({
    items: faqData(),
    description: prismicData(pageData, 'Description', ''),
    uid,
    image: prismicData(pageData, 'Image.url', ''),
  });

  const schemas = {
    website: { schema: JSON.stringify(websiteSchema), path: '/' },
    faq: { schema: JSON.stringify(faqSchema), path: '/faqs' },
    expenseManagement: { schema: JSON.stringify(expenseManagementSchema), path: '/demo' },
    businessCreditCard: { schema: JSON.stringify(businessCreditCardSchema), path: '/product/business-credit-card' },
  };

  const schema =
    (isCurrentPath(schemas.website.path) && schemas.website.schema) ||
    (isCurrentPath(schemas.faq.path) && schemas.faq.schema) ||
    (isCurrentPath(schemas.expenseManagement.path) && schemas.expenseManagement.schema) ||
    (isCurrentPath(schemas.businessCreditCard.path) && schemas.businessCreditCard.schema) ||
    undefined;

  const seo = {
    title: prismicData(pageData, 'Title', ''),
    description: prismicData(pageData, 'Description', ''),
    ogImage: prismicData(pageData, 'Image.url', ''),
    exclude_from_search_engines: prismicData(pageData, 'exclude_from_search_engines', false),
    exclude_canonical_link: prismicData(pageData, 'exclude_canonical_link', false),
    last_publication_date: pageData?.last_publication_date,
    schema,
  } as const;

  return (
    <PageContainer headerId={headerId} footerId={footerId} seoProps={seo}>
      <SliceZone slices={slices} components={components} />
    </PageContainer>
  );
};

export default withPrismicPreview(Page);

export const query = graphql`
  query Page($url: String!) {
    prismicPage(url: { eq: $url }) {
      _previewable
      url
      last_publication_date
      data {
        ...SEO
        ...PageHeader
        ...PageFooter
        slices {
          ...Slice
          ...Hero
          ...Quote
          ...Faqs
          ...ContentHubCards
          ...ThreeColumnCards
          ...TwoColumnThreeCards
          ...TwoColumnFourCards
          ...TwoColumnContentImage
          ...TwoColFloatingContent
          ...ThreeColumnFiveCards
          ...BottomCta
          ...Steps
          ...Logos
          ...ContentInfoBlock
          ...FiveColumnsPanel
          ...Title
          ...ApplePay
          ...Pricing
          ...CompanyQuote
          ...Demo
          ...Wysiwyg
          ...GridAreaCards
          ...SocialProof
          ...Cards
          ...Stats
          ...EmailCapture
          ...Sponsors
          ...RelatedResources
          ...VideoEmbed
          ...FeaturePricingComparison
          ...FxCalculator
          ...TwoColumnProcess
        }
        footer {
          id
        }
      }
    }
  }
`;
