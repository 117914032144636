import { graphql, useStaticQuery } from 'gatsby';
import { Mutable } from 'gatsby-source-prismic';
import React, { FC } from 'react';
import { Defined, ExtractFromArray } from '../../../utilityTypes';
import { prismicData } from '../../../utils/lodashHelpers';
import Header, { OnMount } from '../Header';
import { QueriedMegaDropdownSection, QueriedMegaDropdownSectionItem } from './types';

type HeaderProps = { id?: string | null; onMount?: OnMount };
/**
 * Header data component: specifies the fragment and passes the appropriate data to the Header component.
 */
const HeaderData: FC<HeaderProps> = ({ id, onMount }) => {
  const queryData: Queries.HeaderQuery = useStaticQuery(graphql`
    fragment HeaderItemLink on PrismicHeaderItemLink {
      primary {
        heading
        link {
          link_type
          url
        }
      }
    }

    fragment HeaderItemMegaDropdown on PrismicHeaderItemMegaDropdown {
      primary {
        heading
        leftSection {
          id
        }
        rightSection {
          id
        }
      }
    }

    query Header {
      allPrismicHeader {
        nodes {
          prismicId
          data {
            brandLogoLink {
              ...PrismicLink
            }
            megaDropdownBottomLink {
              ...PrismicLink
            }
            megaDropdownBottomText
            rightSidePrimaryButtonText
            rightSideSecondaryButton1Text
            rightSideSecondaryButton2Text
            rightSidePrimaryButtonLink {
              ...PrismicLink
            }
            rightSideSecondaryButton1Link {
              ...PrismicLink
            }
            rightSideSecondaryButton2Link {
              ...PrismicLink
            }
            tourButtonMobileText
            tourButtonMobileLink {
              ...PrismicLink
            }
            slices {
              ...Slice
              ...HeaderItemLink
              ...HeaderItemMegaDropdown
            }
          }
        }
      }
      allPrismicHeaderMegaDropdownSection {
        nodes {
          prismicId
          data {
            heading
            text
            items {
              heading
              text
              link {
                ...PrismicLink
              }
            }
          }
        }
      }
    }
  `);

  const headerDefault = queryData.allPrismicHeader.nodes[0];
  const header = queryData.allPrismicHeader.nodes.find(({ prismicId }) => prismicId === id) || headerDefault;
  type Slice = ExtractFromArray<Mutable<Defined<typeof header>['data']['slices']>>;
  const getMegaDropdownSection = (id?: string | null) =>
    queryData.allPrismicHeaderMegaDropdownSection.nodes.find(({ prismicId }) => prismicId === id) || null;

  const getLinkSlice = (slicePrimary: Queries.HeaderItemLinkFragment['primary']) => ({
    heading: slicePrimary.heading || '',
    link: slicePrimary.link,
  });

  const getMegaDropdownSectionItemProps = (item: QueriedMegaDropdownSectionItem) => ({
    heading: item.heading || '',
    text: item.text || '',
    link: item.link,
  });

  const getMegaDropdownSectionProps = (section: QueriedMegaDropdownSection | null) => ({
    heading: section?.data.heading || '',
    text: section?.data.text || '',
    items:
      section?.data.items
        ?.filter((item): item is Defined<typeof item> => !!item)
        .map(getMegaDropdownSectionItemProps) || [],
  });

  const getMegaDropdownSlice = (slicePrimary: Queries.HeaderItemMegaDropdownFragment['primary']) => ({
    heading: slicePrimary.heading || '',
    leftSection: getMegaDropdownSectionProps(getMegaDropdownSection(slicePrimary.leftSection?.id)),
    rightSection: getMegaDropdownSectionProps(getMegaDropdownSection(slicePrimary.rightSection?.id)),
  });

  const getSlice = (slice: Slice) =>
    'link' in slice.primary ? getLinkSlice(slice.primary) : getMegaDropdownSlice(slice.primary);

  const slices = header?.data.slices.map(getSlice) || [];

  return (
    <Header
      brandLogoLink={prismicData(header, 'brandLogoLink')}
      megaDropdownCta={{
        text: prismicData(header, 'megaDropdownBottomText'),
        link: prismicData(header, 'megaDropdownBottomLink'),
      }}
      secondaryCta1={{
        text: prismicData(header, 'rightSideSecondaryButton1Text'),
        link: prismicData(header, 'rightSideSecondaryButton1Link'),
      }}
      secondaryCta2={{
        text: prismicData(header, 'rightSideSecondaryButton2Text'),
        link: prismicData(header, 'rightSideSecondaryButton2Link'),
      }}
      tourCtaMobile={{
        text: prismicData(header, 'tourButtonMobileText'),
        link: prismicData(header, 'tourButtonMobileLink'),
      }}
      primaryCta={{
        text: prismicData(header, 'rightSidePrimaryButtonText'),
        link: prismicData(header, 'rightSidePrimaryButtonLink'),
      }}
      items={slices}
      onMount={onMount}
    />
  );
};

export default HeaderData;

export const query = graphql`
  fragment PageHeader on PrismicPageDataType {
    header {
      id
    }
  }

  fragment BlogPostHeader on PrismicBlogPostDataType {
    header {
      id
    }
  }

  fragment NotFoundPageHeader on Prismic404pageDataType {
    header {
      id
    }
  }
  fragment GuidesHeader on PrismicGuidesDataType {
    header {
      id
    }
  }

  fragment EventsHeader on PrismicEventsDataType {
    header {
      id
    }
  }
`;
