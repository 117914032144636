import {
  siteUrl as url,
  description,
  ogImage,
  alternateName,
  email,
  tagline,
  logo as logoUrl,
  name,
  currentYear,
  socials,
  addressCountry,
  postalCode,
  streetAddress,
  addressRegion,
  legalName,
  language as inLanguage,
} from '../../../../config/site';

/**
 * Generate formatted ID for schema
 * https://developer.yoast.com/features/schema/technology-approach/#constructing-id-parameters.
 */
export const generateSchemaId = (type: string, id: string) => `${url}/#/schema/${type}${id}`;

const ids = {
  website: generateSchemaId('WebSite', '1'),
  organization: generateSchemaId('Organization', '1'),
  expenseManagement: `${url}/#expense-management-demo`,
  productSuite: `${url}/#product-suite`,
  businessCreditCard: `${url}/#business-credit-card`,
};
const copyright = { copyrightHolder: { '@id': ids.organization }, copyrightYear: currentYear };

/**
 * T H I N G   S C H E M A S.
 * + https://schema.org/Thing.
 */

// https://schema.org/PostalAddress
const address = {
  '@type': 'PostalAddress',
  addressCountry,
  streetAddress,
  postalCode,
  addressRegion,
};

// https://schema.org/subOrganization
const subOrganization = {
  '@type': 'Organization',
  name: 'Australian CFO Community',
  url: 'https://letsweel.com/resources/the-weelhouse/cfo-community',
  parentOrganization: ids.organization,
};

// https://schema.org/logo
const logo = {
  '@type': 'ImageObject',
  caption: 'Logo',
  url: logoUrl,
  ...copyright,
};

// https://schema.org/ImageObject
const image = {
  '@type': 'ImageObject',
  caption: 'Social Image',
  url: ogImage,
  ...copyright,
};

// https://schema.org/Person
const person = {
  '@type': 'Person',
  affiliation: { '@id': ids.organization },
};

/**
 * P R I M A R Y  E N T I T Y  S C H E M A S.
 * + https://developer.yoast.com/features/schema/technology-approach/#primary-entities.
 */

// https://schema.org/Organization
const organization = {
  '@type': 'Organization',
  '@id': ids.organization,
  name,
  legalName,
  address,
  email,
  alternateName,
  url,
  logo,
  image,
  sameAs: socials,
  subOrganization,
  person,
};

// https://schema.org/WebSite
const website = {
  '@type': 'WebSite',
  '@id': ids.website,
  url,
  name,
  description: tagline,
  about: description,
  datePublished: '2019-01-18T10:30:00+01:00',
  mainEntityOfPage: url,
  sameAs: socials,
  image,
  inLanguage,
  ...copyright,
};

// https://schema.org/WebPage
const webpage = {
  '@type': 'WebPage',
  author: { '@id': ids.organization },
  isPartOf: { '@id': ids.website },
  inLanguage,
  ...copyright,
};

/**
 * W E B P A G E  E N T I T Y  S C H E M A S.
 */

// https://schema.org/FAQPage
const faqPage = {
  '@type': 'FAQPage',
  relatedLink: 'https://letsweel.com/resources/the-weelhouse',
  reviewedBy: { '@id': ids.organization },
  author: { '@id': ids.organization },
  inLanguage,
};

// https://schema.org/Article
const article = {
  '@type': 'Article',
  inLanguage,
  ...copyright,
};

// https://schema.org/Event
const event = {
  '@type': 'Event',
  inLanguage,
  organizer: { '@id': ids.organization },
};

// https://schema.org/WebApplication - Expense Management
const expenseManagementApplication = {
  '@type': 'WebApplication',
  '@id': ids.expenseManagement,
  mainEntityOfPage: `${url}/demo`,
  applicationCategory: 'BusinessApplication',
  applicationSubCategory: 'Expense management software',
  name: 'Weel Expense Management Software',
  applicationSuite: 'Weel',
  operatingSystem: 'all',
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '4.4',
    reviewCount: '34',
  },
  offers: {
    '@type': 'AggregateOffer',
    url: `${url}/demo`,
    priceCurrency: 'AUD',
    lowPrice: '135',
    highPrice: '135',
    availability: 'http://schema.org/InStock',
    itemCondition: 'http://schema.org/NewCondition',
  },
  creator: {
    '@type': 'Organization',
    url: url,
    '@id': ids.organization,
    name: name,
  },
  isPartOf: {
    '@type': 'WebApplication',
    '@id': ids.productSuite,
    name: 'Weel Product Suite',
    applicationCategory: 'BusinessApplication',
    operatingSystem: 'all',
  },
  additionalProperty: [
    {
      '@type': 'PropertyValue',
      name: 'Feature',
      value: 'Receipt Keeping App',
      url: `${url}/product/receipt-keeping-app`,
    },
    {
      '@type': 'PropertyValue',
      name: 'Feature',
      value: 'Accounting Integrations',
      url: `${url}/product/accounting-integrations`,
    },
    {
      '@type': 'PropertyValue',
      name: 'Feature',
      value: 'Business Signup Portal',
      url: 'https://app.letsweel.com/app/business-signup',
    },
  ],
  video: {
    '@type': 'VideoObject',
    name: 'Weel Expense Management Demo',
    url: 'https://www.youtube.com/watch?v=XQMe-6dCaO8',
    thumbnailUrl: 'https://img.youtube.com/vi/XQMe-6dCaO8/maxresdefault.jpg',
    uploadDate: '2025-01-01',
    description:
      "Learn how Weel's expense management software simplifies tracking and managing expenses in this detailed demo video.",
  },
  audience: {
    '@type': 'Audience',
    audienceType: 'Small to medium-sized businesses',
    description: 'Designed for teams managing business expenses efficiently',
  },
};

// https://schema.org/WebApplication - Business Credit Card
const businessCreditCardApplication = {
  '@type': 'WebApplication',
  '@id': ids.businessCreditCard,
  mainEntityOfPage: `${url}/product/business-credit-card`,
  applicationCategory: 'BusinessApplication',
  applicationSubCategory: 'Business credit card management software',
  name: 'Weel Business Credit Card',
  applicationSuite: 'Weel',
  operatingSystem: 'all',
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '4.4',
    reviewCount: '34',
  },
  offers: {
    '@type': 'AggregateOffer',
    url: `${url}/product/business-credit-card`,
    priceCurrency: 'AUD',
    lowPrice: '135',
    highPrice: '135',
    availability: 'http://schema.org/InStock',
    itemCondition: 'http://schema.org/NewCondition',
  },
  creator: {
    '@type': 'Organization',
    url: url,
    '@id': ids.organization,
    name: name,
  },
  isPartOf: {
    '@type': 'WebApplication',
    '@id': ids.productSuite,
    name: 'Weel Product Suite',
    applicationCategory: 'BusinessApplication',
    operatingSystem: 'all',
  },
  additionalProperty: [
    {
      '@type': 'PropertyValue',
      name: 'Feature',
      value: 'Corporate Expense Tracking',
      url: `${url}/product/expense-management`,
    },
    {
      '@type': 'PropertyValue',
      name: 'Feature',
      value: 'Instant Virtual Cards',
      url: `${url}/product/virtual-cards`,
    },
    {
      '@type': 'PropertyValue',
      name: 'Feature',
      value: 'Custom Spending Limits',
      url: `${url}/product/spend-controls`,
    },
  ],
  video: {
    '@type': 'VideoObject',
    name: 'Weel Business Credit Card Overview',
    url: 'https://www.youtube.com/watch?v=XQMe-6dCaO8',
    thumbnailUrl: 'https://img.youtube.com/vi/XQMe-6dCaO8/maxresdefault.jpg',
    uploadDate: '2025-01-01',
    description:
      "Discover how Weel's business credit card can streamline company spending with custom controls and real-time expense tracking.",
  },
  audience: {
    '@type': 'Audience',
    audienceType: 'Small to medium-sized businesses',
    description: 'Designed for businesses looking to manage expenses with smart credit card solutions.',
  },
};

export const websiteSchema = {
  '@context': 'https://schema.org',
  ...website,
};

export const expenseManagementSchema = {
  '@context': 'https://schema.org',
  ...expenseManagementApplication,
};

export const businessCreditCardSchema = {
  '@context': 'https://schema.org',
  ...businessCreditCardApplication,
};

export default {
  address,
  subOrganization,
  logo,
  image,
  organization,
  website,
  webpage,
  faqPage,
  article,
  event,
  person,
  expenseManagementApplication,
  businessCreditCardApplication,
} as const;
