import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { expandClosedStyles, toggleExpand } from '../../design/animations/display';
import { brand1 } from '../../design/colors/brand';
import { black } from '../../design/colors/greyscale';
import { cardShadowsSubtle } from '../../design/colors/shadows';
import { Divider } from '../../design/components/divider/Divider';
import { OnVisible } from '../../design/components/onVisible/OnVisible';
import { Panel } from '../../design/containers/panel/Panel';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { Heading3 } from '../../design/typography/Typography';

type FaqContainerProps = {
  onClick?: () => void;
};

const Container = styled(Panel).attrs({
  background: 'whiteSolid',
  borderRadius: '12px',
  padding: '32px',
})<FaqContainerProps>`
  ${cardShadowsSubtle};
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ToggleIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: ${spacings['32px']};
  flex-shrink: 0;
  align-self: center;
  background: ${brand1};
  border-radius: 50vw;
  height: ${spacings['32px']};
  width: ${spacings['32px']};
`;

type ToggleProps = {
  open: boolean;
  maxHeight?: number;
};
const ToggleIcon = styled.div<ToggleProps>`
  position: relative;
  &:before,
  &:after {
    content: '';
    display: block;
    width: ${spacings['16px']};
    height: 2px;
    background-color: ${black};
  }
  &:after {
    ${(props) => (props.open ? 'transform: rotate(0deg);' : 'transform: rotate(90deg);')}
    position: absolute;
    top: 0;
    transition: transform 150ms linear;
  }
`;

/**
 * Render ToggleButton.
 */
export const ToggleButton: FC<ToggleProps> = ({ open }) => {
  return (
    <ToggleIconContainer>
      <ToggleIcon open={open} />
    </ToggleIconContainer>
  );
};

const Body = styled.div<ToggleProps>`
  ${expandClosedStyles}
  ${({ open, maxHeight }) => maxHeight && toggleExpand(open, maxHeight)}
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacings['24px']};
`;

type FaqItemProps = {
  heading?: string | null;
  text: PrismicRichTextType;
  index: number;
};
/**
 * Renders FaqItem slice.
 */
const FaqItem: FC<FaqItemProps> = ({ heading, text, index }) => {
  const [showText, setShowText] = useState(false);
  const faqAnswer = useRef<HTMLHeadingElement>(null);
  const scrollHeight = faqAnswer?.current?.scrollHeight;

  return (
    <OnVisible fadeUp style={{ marginBottom: `${spacings['24px']}` }}>
      <Container onClick={() => setShowText(!showText)} data-testid={`faq-item-${index}-toggle`}>
        <HeadingContainer>
          <Heading3 type="heading5Medium" textAlign="left">
            {heading}
          </Heading3>
          <ToggleButton open={showText} />
        </HeadingContainer>
        <Body data-testid={`faq-${index}-content`} open={showText} ref={faqAnswer} maxHeight={scrollHeight}>
          <Spacing top="24px" bottom="24px">
            <Divider styleType="brand2MidOpacity0point3" />
          </Spacing>
          <RichText textType="bodyMRegular" field={text} />
        </Body>
      </Container>
    </OnVisible>
  );
};

export default FaqItem;
