import { CalculatorRowProps } from './FeaturePricingComparison';
import { PlanLimits } from './components/Calculator';
import { Tier } from './components/FeatureRow';

const priceFormulae = {
  basic: { users: 10, payments: 0.75 },
  premium: { users: 8, payments: 0.5 },
  enterprise: { users: 6, payments: 0 },
} as const;

type InitialLimitsAndMetrics = {
  initialLimits: CalculatorRowProps;
  metric: 'users' | 'payments';
};

export type PlanLimitData = Record<
  Tier,
  { limit: { base: number; additional: number }; cost: { base: 0 | 5 | 0.75 | 8 | 0.5 | 10; additional: number } }
>;
/**
 * Gets data for plan cost calculation.
 */
export const getCalculatorData = ({
  initialLimits,
  newLimits,
  metric,
}: InitialLimitsAndMetrics & { newLimits: PlanLimits }) =>
  Object.fromEntries(
    Object.entries(newLimits).map(([tier, limit]) => {
      const plan = tier as Tier;
      const base = initialLimits[metric][plan];
      const additional = limit - base;
      const baseLimitCost = priceFormulae[plan][metric];
      const additionalCost = additional * baseLimitCost;
      return [tier, { limit: { base, additional }, cost: { base: baseLimitCost, additional: additionalCost } }];
    })
  ) as PlanLimitData;

/**
 * Formats the price to a string with two decimal places.
 */
export const formatPlanPrice = (price: number) => `$${price.toFixed(2)}`;
