import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { PrismicImageType } from '../../components/image/prismicImage/PrismicImage';
import { PrismicLinkType } from '../../components/link/data/Link.generateData';
import RichText, { PrismicRichTextType } from '../../components/richText/RichText';
import { flex, padding } from '../../design/animations/display';
import { stickyHeaderShadow } from '../../design/colors/shadows';
import { SliceContainer } from '../../design/containers/sliceContainer/SliceContainer';
import { breakpointMedium, breakpointSmall } from '../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../design/spacing/Spacing';
import { WithClassName } from '../../utilityTypes';
import PostFilter from '../contentHubCards/components/TopicFilter/TopicFilter';
import { Calculator } from './components/Calculator';
import { FeatureHeaderRow } from './components/FeatureHeaderRow';
import FeatureList from './components/FeatureList';
import { Tier } from './components/FeatureRow';
import { FeaturePricingComparisonItem } from './data/FeaturePricingComparison.generateData';

type CalculatorRowData = {
  title: string;
  subtitle: string;
  icon: PrismicImageType;
  enterprise: number;
  premium: number;
  basic: number;
};
export type CalculatorRowProps = {
  payments: CalculatorRowData;
  users: CalculatorRowData;
};
export type FeaturePricingComparisonProps = {
  heading: PrismicRichTextType;
  links: { basic: PrismicLinkType; premium: PrismicLinkType; enterprise: PrismicLinkType };
  features: FeaturePricingComparisonItem[];
  calculatorData?: CalculatorRowProps;
} & WithClassName;

export type FormattedFeatures = ReturnType<typeof formatFeatures>;
/**
 * Formats the features data to be used in the FeatureList component.
 */
export const formatFeatures = (items: FeaturePricingComparisonItem[]) =>
  items.map((feature) => {
    const docData = { ...feature.feature_set?.document }.data;
    const features = docData && docData.features;
    const title = docData && docData.title;
    return { ...{ title, features } };
  });

export const CenteredContainer = styled.div`
  max-width: 1648px;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${spacings['24px']};
`;

const responsiveSpacing = () => css`
  position: relative;
  padding: ${spacings['24px']} 0 0;
  ${breakpointSmall(
    css`
      padding: ${spacings['32px']} 0 0;
      top: 32px;
    `
  )};
  ${breakpointMedium(
    css`
      padding: ${spacings['40px']} 0 0;
      top: ${spacings['40px']};
    `
  )};
`;

const stickyStyles = (isSticking?: boolean) => css`
  ${breakpointSmall(
    css`
      position: sticky;
      ${isSticking && `${stickyHeaderShadow}`};
    `
  )};
`;

const customHeaderColour = '#0b5a65';
const StickyHeader = styled.div<{ isSticking?: boolean }>`
  ${flex({ direction: 'column' })}
  ${responsiveSpacing()}
  ${({ isSticking }) => stickyStyles(isSticking)}
  width: 100%;
  background: ${customHeaderColour};
  z-index: 2;
  border-radius: ${spacings['32px']} ${spacings['32px']} 0 0;
`;

const AnchoredSliceContainer = styled(SliceContainer)`
  position: relative;
  overflow: visible;
`;
const SliceTitle = styled(RichText).attrs({ textType: 'heading3' })`
  ${padding({ all: '24px' })};
  ${breakpointMedium(
    css`
      display: none;
    `
  )};
`;

const ShowOnMobile = styled.div`
  ${flex({ justify: 'center' })}
  padding: 0 ${spacings['24px']} ${spacings['16px']};
  ${breakpointSmall(
    css`
      display: none;
    `
  )};
`;
/**
 * Renders FeaturePricingComparison slice.
 */
const FeaturePricingComparison: FC<FeaturePricingComparisonProps> = ({
  className,
  heading,
  features,
  links,
  calculatorData,
}) => {
  const startingPrices = { basic: 135, premium: 375, enterprise: 2025 } as const;
  const [activeMobileTier, setActiveMobileTier] = useState<Tier>('basic');
  const [offset, setOffset] = useState(0);
  const [prices, setPrices] = useState<Record<Tier, number>>(startingPrices);

  const featureList = formatFeatures(features);
  const tierOptions = ['Basic', 'Premium', 'Enterprise'];
  const isSticking = offset > 60;

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);

    addEventListener('scroll', onScroll, { passive: true });
    return () => removeEventListener('scroll', onScroll);
  }, []);

  return (
    <AnchoredSliceContainer className={className} background="brand3Gradient" padding="none">
      <StickyHeader isSticking={isSticking}>
        <SliceTitle field={heading} />
        <ShowOnMobile>
          <PostFilter
            topics={tierOptions}
            handleFilter={(tier: string) => setActiveMobileTier(tier.toLocaleLowerCase() as Tier)}
          />
        </ShowOnMobile>
        <FeatureHeaderRow
          mobileActiveTier={activeMobileTier}
          links={links}
          isSticking={isSticking}
          prices={prices}
          heading={heading}
        />
      </StickyHeader>
      <CenteredContainer>
        <Spacing top="24px">
          <div>
            {calculatorData && (
              <Calculator
                {...calculatorData}
                currentPrices={prices}
                updatePrices={setPrices}
                mobileActiveTier={activeMobileTier}
              />
            )}
          </div>
        </Spacing>
        <FeatureList features={featureList} activeMobileTier={activeMobileTier} />
      </CenteredContainer>
    </AnchoredSliceContainer>
  );
};

export default FeaturePricingComparison;
