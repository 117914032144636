import React, { ComponentProps, FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import FooterData from '../components/footer/data/Footer.queryData';
import { OnMount } from '../components/header/Header';
import HeaderData from '../components/header/data/Header.queryData';
import { pagePadding } from '../templates/page/styles';
import { WithClassName } from '../utilityTypes';
import { PageHead } from './PageHead';

type ContentContainerProps = { noContentPadding?: boolean; headerHeight: number };

const PageContainerRoot = styled.main``;
const ContentContainer = styled.div<ContentContainerProps>`
  ${(props) =>
    !props.noContentPadding &&
    css`
      ${pagePadding};
      margin-top: ${props.headerHeight}px;
    `}
`;

type Props = {
  headerId?: string;
  footerId?: string;
  noContentPadding?: boolean;
} & ComponentProps<typeof PageHead> &
  WithClassName;
/**
 * Container that provides common spacing for all pages.
 */
const PageContainer: FC<PropsWithChildren<Props>> = ({
  className,
  headerId,
  footerId,
  children,
  seoProps,
  noContentPadding,
}) => {
  const [closeAllDropdowns, setCloseAllDropdowns] = useState<() => void | undefined>();
  const onAnyDropdownOpen: OnMount = useCallback(({ closeAll }) => setCloseAllDropdowns(() => closeAll), []);
  const [offset, setOffset] = useState<number>(0);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const headerEls = headerRef.current?.children;
      const headerHeight = (headerEls?.[0]?.clientHeight || 0) + (headerEls?.[1]?.clientHeight || 0);
      return setOffset(headerHeight);
    };

    addEventListener('resize', () => handleResize());
    return removeEventListener('resize', () => handleResize());
  }, []);

  useEffect(() => {
    const headerEls = headerRef.current?.children;
    const headerHeight = (headerEls?.[0]?.clientHeight || 0) + (headerEls?.[1]?.clientHeight || 0);
    return setOffset(headerHeight);
  }, []);

  return (
    <PageContainerRoot className={className} onClick={closeAllDropdowns} data-testid="page-container">
      <PageHead seoProps={seoProps} />
      {headerId && (
        <div ref={headerRef}>
          <HeaderData id={headerId} onMount={onAnyDropdownOpen} />
        </div>
      )}
      <ContentContainer noContentPadding={noContentPadding} headerHeight={offset}>
        {children}
        {footerId && <FooterData id={footerId} />}
      </ContentContainer>
    </PageContainerRoot>
  );
};

export default PageContainer;
